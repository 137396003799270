<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb
        :title="city"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: true, url: '/city', text: 'Şehirler' },
          { isCity: true },
        ]"
        :city="$route.params.city"
        scity="true"
      />
    </section>
    <!--/Breadcrumb-->

    <!--Add listing-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-xl-9 col-lg-9 col-md-9" id="mobileEx">
            <!--Add lists-->
            <div class="mb-lg-0">
              <ProfileItem :list="list" :isLoad="isExpertLoad" />
              <div class="center-block text-center mt-5">
                <Pagination
                  :currentpage="currentpage"
                  :pagesize="pagesize"
                  :preload="'/city/' + $route.params.city + '/expert/'"
                />
              </div>
            </div>
            <!--Add lists-->
          </div>
          <div class="col-xl-3 col-lg-4 col-md-12">
            <Experience />
          </div>
        </div>
      </div>
    </section>
    <!--Add Listing-->
  </div>
</template>
<style scoped>
@media screen and (max-width: 990px) {
  #mobileEx {
    padding-left: 35px;
    padding-right: 35px;
  }
}
</style>
<script>
import ProfileItem from "./Components/ProfileItem.vue";
import Experience from "./Components/Experience.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
import Pagination from "../../components/Pagination.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        { name: "robots", content: "noindex" },
        { name: "googlebot", content: "noindex" },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    let experienceInfo;
    let cityInfo = {};
    this.$store.dispatch("citysGet", cityInfo).then((value) => {
      this.cityList = value.list;
      this.city = this.cityList.filter(
        (country) => country.shortname == this.$route.params.city
      )[0].name;
      this.districtList = this.cityList.filter(
        (country) => country.shortname == this.$route.params.city
      )[0].districts;

      this.getList();
    });
  },
  data() {
    return {
      cityList: [],
      districtList: [],
      info: [],
      list: [],
      city: "",
      filter: [],
      filtered: "",
      serviceList: [],
      currentpage: 1,
      perpage: 9,
      totalsize: 0,
      pagesize: 0,
      isExpertLoad: true,
      title: "",
      description: "",
    };
  },
  methods: {
    getList() {
      var experienceInfo = [];
      if (
        this.$store.state.search != null &&
        this.$route.params.location != null
      ) {
        experienceInfo = {
          search: this.$store.state.search,
          location: this.city,
          begin: (this.currentpage - 1) * this.perpage,
          perpage: this.perpage,
        };
      }
      if (this.$store.state.search != null && !this.$route.params.location) {
        experienceInfo = {
          search: this.$store.state.search,
          begin: (this.currentpage - 1) * this.perpage,
          perpage: this.perpage,
        };
      }
      if (!this.$store.state.search && !this.$route.params.location != null) {
        experienceInfo = {
          location: this.$route.params.city,
          begin: (this.currentpage - 1) * this.perpage,
          perpage: this.perpage,
        };
      }
      experienceInfo.filtered = this.filtered;
      this.$store
        .dispatch("serviceUserFilter", experienceInfo)
        .then((value) => {
          this.title = this.city + " Bölgesindeki Hastaneler, Kurumlar ve Terapistler";
          this.info = value.info;
          this.description =
            this.city +
            " bölgesinde faaliyet gösteren terapi ve sağlık kurumlarını listeleyebilir, Yorumları inceleyebilir, randevu alabilirsiniz.";
          let _this = this;
          $(document).ready(function () {
            let content = document.getElementById("appcontent").innerHTML;
            _this.$store.dispatch("setMetaContent", [
              _this.title,
              _this.description,
              "",
              "",
              "",
              content,
            ]);
          });
          this.list = value.users;
          this.totalsize = value.size;
          this.pagesize = value.pages;
          this.filter = [];
          this.filter = value.filter;
          this.isExpertLoad = false;
        });
    },
    filterItem(item) {
      let t = [];
      if (this.filtered != "") {
        t = this.filtered.split(",");
      }
      var found = t.indexOf(item);

      if (found < 0) {
        t.push(item);
      } else {
        t.splice(found, 1);
      }
      this.filtered = t.toString();
      this.getList();
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
  },
  components: { ProfileItem, Experience, Breadcrumb, Pagination },
  mounted() {
    this.backToTop();
    let serviceInfo = {};
    this.$store.dispatch("serviceList", serviceInfo).then((value) => {
      this.serviceList = value;
    });
  },
};
</script>